<template>
  <ul class="price-wrap">
    <li v-for="(itm, idx) in list" :class="{ head: idx === 0 }" :key="idx">
      <section>{{ itm.area }}</section>
      <section>{{ itm.type }}</section>
      <section>{{ itm.size }}</section>
      <section>{{ itm.disk }}</section>
      <section :class="{ line2: idx === 0 }">{{ itm.band }}</section>
      <section>{{ itm.month }}</section>
      <section>{{ itm.halfYear }}</section>
      <section>{{ itm.year }}</section>
      <section v-if="itm.pay">{{ itm.pay }}</section>
      <section v-if="!itm.pay" class="pay" @click="hanleTab(itm.month, idx)">
        購入
      </section>
    </li>
  </ul>
</template>

<script>
export default {
  name: "price",
  data() {
    return {
      list: [
        {
          area: "地域",
          type: "モデル",
          size: "メモリ",
          disk: "クラウドディスク",
          band: "パブリックネットワーク帯域幅",
          month: "月額料金",
          halfYear: "四半期ごとの価格",
          year: "年間価格",
          pay: "支払い",
        },
        {
          area: "東京",
          type: "AMD",
          size: "1C2G",
          disk: "40G SSD",
          band: "2Mbps",
          month: "2210.48円",
          halfYear: "6298.26円",
          year: "21215.21円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "2C4G",
          disk: "40G SSD",
          band: "4Mbps",
          month: "4383.36円",
          halfYear: "12492.56円",
          year: "42080.22円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "4C8G",
          disk: "40G SSD",
          band: "4Mbps",
          month: "5260.27円",
          halfYear: "14991.78円",
          year: "50498.64円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "8C16G",
          disk: "40G SSD",
          band: "8Mbps",
          month: "10482.57円",
          halfYear: "29857.31円",
          year: "100632.64円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "16C32G",
          disk: "40G SSD",
          band: "8Mbps",
          month: "13991.48円",
          halfYear: "39875.72円",
          year: "134318.21円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "32C64g",
          disk: "40G SSD",
          band: "8Mbps",
          month: "21008.48円",
          halfYear: "59874.18円",
          year: "201681.44円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "-",
          disk: "40G SSD",
          band: "-",
          month: "136.66円",
          halfYear: "389.48円",
          year: "1508.73円",
          tab: "/pay",
        },
        {
          area: "東京",
          type: "AMD",
          size: "-",
          disk: "10G SSD",
          band: "1Mbps 帯域幅",
          month: "527.88円",
          halfYear: "1504.46円",
          year: "5287.8円",
          tab: "/pay",
        },
      ],
    };
  },
  methods: {
    hanleTab(item, index) {
      const ems = item.replace("円", "");
      this.$router.push(`/pay?money=${ems}&&number=${index}`);
    },
  },
};
</script>

<style lang="less" scoped>
.price-wrap {
  width: 1280px;
  margin: 0 auto;

  > li {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: #324065;

    > * {
      padding: 20px 30px;
    }

    .line2 {
      margin-top: -10px;
    }

    > section {
      &:nth-of-type(1) {
        width: 32px;
      }

      &:nth-of-type(2) {
        width: 48px;
      }

      &:nth-of-type(3) {
        width: 48px;
      }

      &:nth-of-type(4) {
        width: 128px;
      }

      &:nth-of-type(5) {
        width: 140px;
      }

      &:nth-of-type(6) {
        width: 75px;
      }

      &:nth-of-type(7) {
        width: 128px;
      }

      &:nth-of-type(8) {
        width: 85px;
      }
    }

    &.head {
      color: #195abe;
    }

    &:nth-of-type(2n-1) {
      background: rgba(25, 90, 190, 0.05);
    }
  }
}
.pay {
  cursor: pointer;
  color: #f08c1b;
  &:hover {
    text-decoration: underline;
    color: #f08c1b;
  }
}
</style>
