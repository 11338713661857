<template>
  <div>
    <div class="describe-content">
      <ul>
        <li v-for="itm in list">
          <div>
            <img ondragstart="return false;" :src="itm.icon" />
            <h4>{{ itm.title }}</h4>
          </div>
          <section>
            <p>{{ itm.text }}</p>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      list: [
        {
          icon: "./images/icon/_i_001.svg",
          title: "柔軟なコンピューティング",
          text: "急速に変化するビジネスニーズに対応するために、数分以内にクラウドの数をすばやく増減できます。",
        },
        {
          icon: "./images/icon/_i_002.svg",
          title: "多様な構成",
          text: "ユーザーのさまざまなニーズに応えて、CVMは複数のタイプのインスタンス、オペレーティングシステム、およびソフトウェアパッケージを提供します。",
        },
        {
          icon: "./images/icon/_i_003.svg",
          title: "安定して信頼できる",
          text: "CVMを搭載したクラウドハードディスクは、3コピーのストレージ戦略を提供します。これにより、コピーが失敗した場合にデータを迅速に移行および復元できます。",
        },
        {
          icon: "./images/icon/_i_004.svg",
          title: "安全なネットワーク",
          text: "ネットワークアクセス制御リストとセキュリティグループを通じて、クラウドリソースのセキュリティを効果的に確保できます。",
        },
        {
          icon: "./images/icon/_i_005.svg",
          title: "包括的な保護",
          text: "高防御システムの超大帯域幅と超強力なクリーニング機能は、さまざまなWebサイト攻撃に対処し、Webサイトビジネスサービスの外部への安定した運用を保証するように設計されています。",
        },
        {
          icon: "./images/icon/_i_006.svg",
          title: "低価格",
          text: "CVMはクラウドに導入されるため、初期段階で基本的なネットワーク設備を構築するコストと、後の段階でメンテナンスコストを大幅に節約できます。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.describe-content {
  margin: 40px auto 0;
  text-align: left;
  > ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      padding: 30px 40px;
      box-sizing: border-box;
      width: calc(100% / 3 - 2px);
      max-width: calc(100% / 3);
      background: #fff;
      height: auto;
      border-left: 1px solid rgba(56, 96, 244, 0.05);
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        border-bottom: 1px solid rgba(56, 96, 244, 0.05);
      }
      &:nth-of-type(3n + 1) {
        border-left: 0;
      }
      > div {
        > img {
          width: 45px;
          height: 45px;
          vertical-align: middle;
          background-position: center;
        }

        > h4 {
          margin-left: 10px;
          display: inline-block;
          font-size: 22px;
          vertical-align: middle;
        }
      }
      > section {
        margin-top: 15px;
        > p {
          color: #7a8ca6;
          font-size: 16px;
        }
      }
      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
